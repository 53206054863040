import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import moment from 'moment';
import styled from 'styled-components'
import { BrowserRouter } from "react-router-dom";
import { Card, Label } from './global/styles';

import { WatchlistDataProvider } from './WatchlistDataProvider';
import Watchlist from './Watchlist';
import { PairDataProvider } from './PairDataProvider';
import WebsocketConsumer from './WebsocketConsumer';

export default function WatchlistPage() {

  return (
    <div className="App">

        <WatchlistDataProvider>
          <Watchlist />
        </WatchlistDataProvider>

    </div>
  );
}