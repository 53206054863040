import React, { useEffect, useState } from 'react';
import { createChart, CrosshairMode, LineStyle, UTCTimestamp } from 'lightweight-charts';
import PropTypes from 'prop-types';

import { PositionTool } from './libs/position-tool/positiontool';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Rectangle, RectangleDrawingTool } from './libs/rectangle-drawing-tool';
import { Rectangletool } from './libs/lwc-plugin-rectangle-tool/src/rectangle-tool';

const HEIGHT = 500;

let chart;
let candlestickSeries;

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : ''

console.log('PROCESS ENV', process.env.REACT_APP_BACKEND_URL);


const ChartSMC = ({ legend, initCandles, lastCandle, decimals }) => {
  const { pair } = useParams();
  const chartRef = React.useRef<HTMLDivElement | null>(null);
  const legendRef = React.useRef<HTMLDivElement | null>(null);
  const [candles, setCandles] = useState({})

  useEffect(() => {
    if (!chartRef.current) return;

    let data: any = []

    if (candles && (candles as any).candles) {
      data = (candles as any).candles.map(candle => {
        const [ts, open, high, low, close] = candle
        return { time: ts, open, high, low, close }
      })
    }


    if (data.length > 0) {

      chart = createChart(chartRef.current, {
        width: chartRef.current.offsetWidth,
        height: HEIGHT,
        // alignLabels: true,
        timeScale: {
          rightOffset: 0,
          barSpacing: 15,
          fixLeftEdge: false,
          lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          borderVisible: false,
          borderColor: '#fff000',
          visible: true,
          timeVisible: true,
          secondsVisible: false
        },
        rightPriceScale: {
          scaleMargins: {
            top: 0.3,
            bottom: 0.25,
          },
          borderVisible: false,
        },
        // priceScale: {
        //     autoScale: true,
        // },
        watermark: {
          color: 'rgba(0, 0, 0, 0.7)',
          visible: true,
          text: 'BrrrBOT',
          fontSize: 18,
          horzAlign: 'left',
          vertAlign: 'bottom',
        },
        crosshair: {
          mode: CrosshairMode.Normal,
        },
      });

      candlestickSeries = chart.addCandlestickSeries({
        priceScaleId: 'right',
        upColor: '#00AA00',
        downColor: '#AA0000',
        borderVisible: false,
        wickVisible: true,
        borderColor: '#000000',
        wickColor: '#000000',
        borderUpColor: '#00AA00',
        borderDownColor: '#AA0000',
        wickUpColor: '#00AA00',
        wickDownColor: '#AA0000',
        priceFormat: {
          type: 'custom',
          minMove: '0.00000001',
          formatter: (price) => {
            return parseFloat(price).toFixed(5);
          }
        },
      });

      candlestickSeries.setData(data);

      if (candles && (candles as any).structures) {
        (candles as any).structures.forEach((s: any) => {


          const lineSeries = chart.addLineSeries({
            color: s.bias === 'bullish' ? '#00AA00' : '#AA0000',
            lineWidth: 1,
            priceLineVisible: false,
            lineStyle: LineStyle.Dashed
          });

          lineSeries.setData([
            { time: s.startTime, value: parseFloat(s.price) },
            { time: s.endTime, value: parseFloat(s.price) },
          ]);
        })
      }

      if (candles && (candles as any).orderBlocks) {
        (candles as any).orderBlocks.forEach((ob: any) => {
          console.log('OB', ob);

          const endTime = (candles as any).candles[(candles as any).candles.length - 1][0]

          console.log('OB', ob );
          
          const rect = new Rectangle(
            { time: ob.barTime as UTCTimestamp, price: parseFloat(ob.barLow) },
            { time: endTime as UTCTimestamp, price: parseFloat(ob.barHigh) },
            {
              fillColor: ob.bias == -1 ? '#f2364555' : '#04998155',
              previewFillColor: 'rgba(200, 50, 100, 0.25)',
              labelColor: 'rgba(200, 50, 100, 1)',
              labelTextColor: 'white',
            })

            candlestickSeries.attachPrimitive(rect)

        })

      }

      // const lineSeries2 = chart.addLineSeries();

      // new RectangleDrawingTool(
      //   chart,
      //   lineSeries2,
      //   document.querySelector<HTMLDivElement>('#toolbar')!,
      //   {
      //     showLabels: true,
      //   }
      // );



      // structures
      //     bias
      // : 
      // "bearish"
      // endIndex
      // : 
      // 15
      // endTime
      // : 
      // 1730762160
      // price
      // : 
      // 1.3347
      // startIndex
      // : 
      // 4
      // startTime
      // : 
      // 1730761500
      // type
      // : 
      // "BOS"



      // const lineSeries = chart.addLineSeries({
      //   color: 'black',
      //   lineWidth: 1,
      //   priceLineVisible: false,
      //   // lineStyle: LineStyle.Dashed
      // });

      // lineSeries.setData([
      //   { time: time1, value: parseFloat(p.exit_price) },
      //   { time: time2, value: parseFloat(p.exit_price) },
      // ]);



      // const stopLossArea = new Rectangle(
      //   { price: trade.entry, time: dateToUtcTimestamp(trade.entryTime) },
      //   {
      //     price: trade.sl,
      //     time: dateToUtcTimestamp(
      //       adjustExitTime(trade.entryTime, rMultipleDataForTrade.exitTime)
      //     ),
      //   },
      //   {
      //     fillColor: "rgba(242, 25, 25, 0.6)",
      //   }
      // );

      // const positions = (candles as any).positions

      // if (positions) {

      //   for (let p of positions) {
      //     let time1 = p.ts_open
      //     let time2 = p.ts_closed

      //     time1 = Math.floor(time1 / 60) * 60;
      //     time2 = Math.floor(time2 / 60) * 60;

      //     if (time1 === time2) {
      //       time2 = time2 + 60;
      //     }
      //     if (p.is_closed) {
      //       if (p.custom_data) {
      //         const customData = JSON.parse(p.custom_data)
      //         const atr = parseFloat(customData.atr)

      //         let sl = 0
      //         let tp = 0
      //         if (p.side === 'long') {
      //           sl = parseFloat(p.entry_price) - (atr * 4)
      //           tp = parseFloat(p.entry_price) + (atr * 4)
      //         } else {
      //           sl = parseFloat(p.entry_price) + (atr * 4)
      //           tp = parseFloat(p.entry_price) - (atr * 4)
      //         }

      //         console.log('ENTRY', p.entry_price, 'SL', sl, 'TP', tp);

      //         const primitive = new PositionTool(
      //           {
      //             entryPrice: parseFloat(p.entry_price),
      //             stopLoss: sl,
      //             takeProfit: tp,
      //             entryTime: time1, closeTime: time2,
      //             pnl: parseFloat(p.pnl),
      //           },
      //         )

      //         candlestickSeries.attachPrimitive(primitive);

      //         // Define the start and end timestamps in seconds


      //         // Add the two data points with the same price to create a line

      //         console.log('EXIT P', parseFloat(p.exit_price), time1, time2);


      //           const lineSeries = chart.addLineSeries({
      //             color: 'black',
      //             lineWidth: 1,
      //             priceLineVisible: false,
      //             // lineStyle: LineStyle.Dashed
      //           });

      //           lineSeries.setData([
      //             { time: time1, value: parseFloat(p.exit_price) },
      //             { time: time2, value: parseFloat(p.exit_price) },
      //           ]);


      //       }
      //     }
      //   }
      // }


      let linedata_high: any = []
      let linedata_low: any = []
      let linedata_fast_high: any = []
      let linedata_fast_low: any = []

      const sma = (candles as any).wws1m

      console.log('SMA', sma);

      if (sma && sma.length > 0) {
        linedata_high = sma.map(row => ({ time: row.ts, value: parseFloat(row.sh) }))
        linedata_low = sma.map(row => ({ time: row.ts, value: parseFloat(row.sl) }))
        linedata_fast_high = sma.map(row => ({ time: row.ts, value: parseFloat(row.fh) }))
        linedata_fast_low = sma.map(row => ({ time: row.ts, value: parseFloat(row.fl) }))
      }

      chart.addLineSeries({
        color: '#ff040244',
        lineWidth: 2,
        priceLineVisible: false,
        lastValueVisible: false,
        priceLineSource: false,
        crossHairMarkerVisible: false,
        lineStyle: 0,
        lineType: 0,
      }).setData(linedata_high);

      chart.addLineSeries({
        color: '#ff040244',
        lineWidth: 2,
        priceLineVisible: false,
        lastValueVisible: false,
        priceLineSource: false,
        crossHairMarkerVisible: false,
        lineStyle: 0,
        lineType: 0,
      }).setData(linedata_low);

      chart.addLineSeries({
        color: '#0ca10944',
        lineWidth: 2,
        priceLineVisible: false,
        lastValueVisible: false,
        priceLineSource: false,
        crossHairMarkerVisible: false,
        lineStyle: 0,
        lineType: 0,
      }).setData(linedata_fast_high);

      chart.addLineSeries({
        color: '#0ca10944',
        lineWidth: 2,
        priceLineVisible: false,
        lastValueVisible: false,
        priceLineSource: false,
        crossHairMarkerVisible: false,
        lineStyle: 0,
        lineType: 0,
      }).setData(linedata_fast_low);



    }
  }, [candles]);

  // useEffect(() => {
  //   candlestickSeries.update(lastCandle);
  // }, [lastCandle]);

  useEffect(() => {
    const handler = () => {
      chart.resize(chartRef.current.offsetWidth, HEIGHT);
    };
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  // GETTING CANDLES DATA

  useEffect(() => {
    async function fetchData() {

      const response = await fetch(`${BACKEND_URL}/api/get-candles-smc?pair=${pair}`);
      let json = await response.json();
      console.log('json', json);
      setCandles(json)

    }
    fetchData();

  }, [pair])

  const scrollToSquare = (from: string, to: string, tp: string, sl: string) => {

    if (chart) {
      chart.timeScale().setVisibleRange({
        from: parseInt(from) - 10 * 60,
        to: parseInt(to) + 10 * 60,
      });

      console.log('PRICE SCALE', chart.priceScale());
    }
  };

  return (
    <>

      <div ref={chartRef} id='chart' style={{ 'position': 'relative', 'width': '100%', 'height': '500px' }}>
        <div
          ref={legendRef}
          style={{
            position: 'absolute',
            zIndex: 2,
            color: '#333',
            padding: 10,
            overflow: 'hidden',
          }}
        >
          {legend}
        </div>
      </div>
      <div id='toolbar'></div>

      <br />
    </>
  );
};

ChartSMC.propTypes = {
  legend: PropTypes.string,
  initCandles: PropTypes.array,
  lastCandle: PropTypes.object,
  decimals: PropTypes.number,
};

export default ChartSMC;