import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { AlertsDataContext } from "./AlertsDataProvier";
import { useConfig } from "./useConfig";
import { use } from "chai";
import { last } from "lodash";
import { formatNumberK } from "./utils";


function requestNotificationPermission() {
    if ('Notification' in window) {  
        Notification.requestPermission().then(permission => { 
            if (permission === "granted") {
                console.log("Notification permission granted.");
                // Now you can show notifications
            } else {                
                console.log("Notification permission denied.");
            }
        });
    }
}

function generateRandom9DigitNumber() {
    // Generate a number between 100000000 (inclusive) and 1000000000 (exclusive),
    // ensuring it's always 9 digits.
    const randomNumber = Math.floor(Math.random() * (1000000000 - 100000000)) + 100000000;
    return randomNumber;
}

export const NotificationComponent = () => {
    const config = useConfig();
    const [worker, setWorker] = useState(null);
    const { pairs$, filter, changeFilter, manualReconnect, disconnect } = React.useContext(AlertsDataContext);
    const [signals, setSignals] = useState<any>({});

    const ONE_HOUR = 60 * 60; // One hour in milliseconds

    useEffect(() => {
        const newWorker = new Worker(new URL('./worker.js', import.meta.url));
        setWorker(newWorker);

        newWorker.onmessage = (event) => {
            const result = event.data;
            setSignals(result)
        };

        // Cleanup the worker when the component unmounts
        return () => {
            newWorker.terminate();
        };
    }, []);

    useEffect(() => {

        if (config && config.alerts) {
            const { active, minVolume } = config.alerts

            const newFilter = `vol_5m > ${minVolume}`

            if (active) {
                if (newFilter !== filter) {
                    changeFilter(newFilter)
                    console.log('APPLYING NEW VOLUME FILTER', newFilter);
                }
            }

            if (!active) {
                disconnect()
            }
        }

    }, [config])

    useEffect(() => {
        let subscription: any = null
        const { alerts: alertsConfig } = config
        if (worker && pairs$ && config) {
            const { active, minVolume } = config.alerts 

            if (active) {

                const newFilter = `vol_5m > ${minVolume}`
                if (newFilter !== filter) {
                    changeFilter(newFilter)
                    console.log('APPLYING NEW VOLUME FILTER WHEN ACTIVATING ', newFilter);
                }
                subscription = pairs$.subscribe((data) => {
                    worker.postMessage({ items: data.items, levelsConfig: alertsConfig })
                });
            }
        }

        // Cleanup subscription when component unmounts
        return () => {
            if (subscription) {
                subscription.unsubscribe()
            }
        };
    }, [worker, pairs$]);

    const getDisplayedNotifications = () => {
        const storedNotifications = localStorage.getItem('displayedNotifications');
        return storedNotifications ? JSON.parse(storedNotifications) : {};
    };

    // Utility function to store the displayed notification info
    const storeNotification = (pair, ts, extra) => {
        const displayedNotifications = getDisplayedNotifications()
        displayedNotifications[pair] = {ts, extra}
        localStorage.setItem('displayedNotifications', JSON.stringify(displayedNotifications))
    }

    const canShowNotification = (pair) => {

        const displayedNotifications = getDisplayedNotifications();
        const lastDisplayedTs = displayedNotifications[pair];

        if (!lastDisplayedTs) {
            return true; // No notification was displayed before, allow showing
        }

        const currentTs = moment().unix();
        const timeDiff = currentTs - lastDisplayedTs;

        return timeDiff > ONE_HOUR; // Allow if more than an hour has passed
    };

    const showNotification = (pair: string, body: string) => {
        if ('Notification' in window && Notification.permission === "granted") {            
            const notification = new Notification(pair, {
                body,
                icon: "/public/logo192.png",
                requireInteraction: true,
                tag: "unique-tag-" + generateRandom9DigitNumber(), // Helps prevent duplicate notifications
            });

            notification.onclick = function (event) {
                event.preventDefault(); // Prevent the browser from focusing the notification
                notification.close();
                const pairLinkTv = `https://www.tradingview.com/chart/?symbol=BINANCE%3A${pair.replace('/', '')}.P`
                window.open(pairLinkTv);
            };
        }
    }

    useEffect(() => {
        const { pdhSignals, pdlSignals } = signals

        if (pdhSignals) {
            signals.pdhSignals.forEach(({ pair, current1mCandle, signalPercentOffset, extra }) => {
                const { ts } = current1mCandle
                const { vol_5m } = extra
                const vol_display = formatNumberK(vol_5m)
                
                if (canShowNotification(pair)) {
                    const percent = signalPercentOffset * 100
                    const content = `Price is within ${percent}% of Prev Day High \n Volume 5m: ${vol_display}`
                    showNotification(pair, content);
                    storeNotification(pair, ts, extra);
                }
            })
        }
        if (pdlSignals) {
            signals.pdlSignals.forEach(({ pair, current1mCandle, signalPercentOffset, extra }) => {
                const { ts } = current1mCandle
                const { vol_5m } = extra
                const vol_display = formatNumberK(vol_5m)

                if (canShowNotification(pair)) {
                    const percent = signalPercentOffset * 100
                    const content = `Price is within ${percent}% of Prev Day Low \n Volume 5m: ${vol_display}`
                    showNotification(pair, content);
                    storeNotification(pair, ts, extra);
                }
            })
        }

    }, [signals]);

    useEffect(() => {
        requestNotificationPermission();
    }, []);

    // const testNofitication = () => {
    //     showNotification('BTC/USDT', 'This is a test notification');
    // }

    // return <button onClick={() => { testNofitication()}}>TEST Notification</button>; // No UI needed
    return null
};