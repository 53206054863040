
import './App.css';
import Signals15m from './Signals15m';
import { Signals15mDataProvider } from './Signals15mDataProvider';

export default function Signals15mPage() {

  return (
    <div className="App">

        <Signals15mDataProvider>
            <Signals15m />
        </Signals15mDataProvider>

    </div>
  );
}