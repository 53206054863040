import React, { useEffect, useState } from 'react';
import { createChart, CrosshairMode, LineStyle } from 'lightweight-charts';
import PropTypes from 'prop-types';

import { PositionTool } from './libs/position-tool/positiontool';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const HEIGHT = 500;

let chart;
let candlestickSeries;

function winRate(positions) {
  const winningTrades = positions.filter(pos => pos.pnl > 0).length;
  return ((winningTrades / positions.length) * 100);
}

function averageWin(positions) {
  const wins = positions.filter(pos => parseFloat(pos.pnl) > 0);
  return wins.reduce((sum, pos) => sum + parseFloat(pos.pnl), 0) / wins.length;
}

function averageLoss(positions) {
  const losses = positions.filter(pos => parseFloat(pos.pnl) < 0);
  return losses.reduce((sum, pos) => sum + parseFloat(pos.pnl), 0) / losses.length;
}

function profitFactor(positions) {
  const grossProfit = positions.filter(pos => parseFloat(pos.pnl) > 0).reduce((sum, pos) => sum + parseFloat(pos.pnl), 0);
  const grossLoss = Math.abs(positions.filter(pos => parseFloat(pos.pnl) < 0).reduce((sum, pos) => sum + parseFloat(pos.pnl), 0));
  return grossProfit / grossLoss;
}

function riskRewardRatio(positions) {
  return averageWin(positions) / Math.abs(averageLoss(positions));
}

function maxDrawdown(positions) {
  let maxEquity = parseFloat(positions[0].updatedAccountSize);
  let maxDD = 0;
  positions.forEach(pos => {
    maxEquity = Math.max(maxEquity, pos.updatedAccountSize);
    maxDD = Math.max(maxDD, (maxEquity - parseFloat(pos.updatedAccountSize)) / maxEquity);
  });
  return maxDD * 100;
}

function sharpeRatio(positions) {
  const avgPnL = positions.reduce((sum, pos) => sum + parseFloat(pos.pnl), 0) / positions.length;
  const stdDev = Math.sqrt(positions.reduce((sum, pos) => sum + Math.pow(parseFloat(pos.pnl) - avgPnL, 2), 0) / positions.length);
  return avgPnL / stdDev;
}

function sortinoRatio(positions, targetReturn = 0) {
  const avgPositivePnL = positions.reduce((sum, pos) => sum + (parseFloat(pos.pnl) > targetReturn ? parseFloat(pos.pnl) : 0), 0) / positions.length;
  const downsideDev = Math.sqrt(positions.reduce((sum, pos) => sum + Math.pow(Math.min(0, parseFloat(pos.pnl) - targetReturn), 2), 0) / positions.length);
  return (avgPositivePnL - targetReturn) / downsideDev;
}

function expectancy(positions) {
  const winRateValue = winRate(positions) / 100;
  const avgWinValue = averageWin(positions);
  const avgLossValue = Math.abs(averageLoss(positions));
  return (winRateValue * avgWinValue) - ((1 - winRateValue) * avgLossValue);
}

function averageHoldingTime(positions) {
  const totalTime = positions.reduce((sum, pos) => sum + (parseInt(pos.closeTime) - parseInt(pos.openTime)), 0);
  return totalTime / positions.length; // in milliseconds
}

function consecutiveStreaks(positions) {
  let maxWinStreak = 0, maxLossStreak = 0;
  let currentWinStreak = 0, currentLossStreak = 0;

  positions.forEach(pos => {
    if (pos.pnl > 0) {
      currentWinStreak++;
      maxWinStreak = Math.max(maxWinStreak, currentWinStreak);
      currentLossStreak = 0;
    } else if (parseFloat(pos.pnl) < 0) {
      currentLossStreak++;
      maxLossStreak = Math.max(maxLossStreak, currentLossStreak);
      currentWinStreak = 0;
    }
  });

  return { maxWinStreak, maxLossStreak };
}

function equityGrowth(positions) {
  const initialEquity = parseFloat(positions[0].updatedAccountSize);
  const finalEquity = parseFloat(positions[positions.length - 1].equity);
  return ((finalEquity - initialEquity) / initialEquity) * 100;
}

function calmarRatio(positions, annualReturn) {
  const maxDD = maxDrawdown(positions) / 100;  // Convert to decimal
  return annualReturn / maxDD;
}

function kellyCriterion(positions) {
  // Calculate win rate as a decimal
  const win_rate = winRate(positions) / 100;

  // Calculate the win-to-loss ratio
  const avgWin = averageWin(positions);
  const avgLoss = Math.abs(averageLoss(positions));
  const winToLossRatio = avgWin / avgLoss;

  // Kelly Criterion formula
  return win_rate - ((1 - win_rate) / winToLossRatio);
}


const ChartPositions = ({ legend, initCandles, lastCandle, decimals }) => {
  const { pair } = useParams();
  const chartRef = React.useRef<HTMLDivElement | null>(null);
  const legendRef = React.useRef<HTMLDivElement | null>(null);
  const [candles, setCandles] = useState({})

  useEffect(() => {
    if (!chartRef.current) return;


    let data: any = []

    // console.log('candles len', candles);

    if (candles && (candles as any).wws1m && (candles as any).wws1m.length > 0) {
      data = (candles as any).wws1m.map(candle => {
        const [ts, open, high, low, close] = candle.candle
        return { time: ts, open, high, low, close }
      })
    }

    if (data.length > 0) {

      chart = createChart(chartRef.current, {
        width: chartRef.current.offsetWidth,
        height: HEIGHT,
        // alignLabels: true,
        timeScale: {
          rightOffset: 0,
          barSpacing: 15,
          fixLeftEdge: false,
          lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          borderVisible: false,
          borderColor: '#fff000',
          visible: true,
          timeVisible: true,
          secondsVisible: false
        },
        rightPriceScale: {
          scaleMargins: {
            top: 0.3,
            bottom: 0.25,
          },
          borderVisible: false,
        },
        // priceScale: {
        //     autoScale: true,
        // },
        watermark: {
          color: 'rgba(0, 0, 0, 0.7)',
          visible: true,
          text: 'BrrrBOT',
          fontSize: 18,
          horzAlign: 'left',
          vertAlign: 'bottom',
        },
        crosshair: {
          mode: CrosshairMode.Normal,
        },
      });

      candlestickSeries = chart.addCandlestickSeries({
        priceScaleId: 'right',
        upColor: '#00AA00',
        downColor: '#AA0000',
        borderVisible: false,
        wickVisible: true,
        borderColor: '#000000',
        wickColor: '#000000',
        borderUpColor: '#00AA00',
        borderDownColor: '#AA0000',
        wickUpColor: '#00AA00',
        wickDownColor: '#AA0000',
        priceFormat: {
          type: 'custom',
          minMove: '0.00000001',
          formatter: (price) => {
            return parseFloat(price).toFixed(5);
          }
        },
      });

      candlestickSeries.setData(data);


      const positions = (candles as any).positions

      if (positions) {

        for (let p of positions) {
          let time1 = p.ts_open
          let time2 = p.ts_closed

          time1 = Math.floor(time1 / 60) * 60;
          time2 = Math.floor(time2 / 60) * 60;

          if (time1 === time2) {
            time2 = time2 + 60;
          }
          if (p.is_closed) {
            if (p.custom_data) {
              const customData = JSON.parse(p.custom_data)
              const atr = parseFloat(customData.atr)

              let sl = 0
              let tp = 0
              if (p.side === 'long') {
                sl = parseFloat(p.entry_price) - (atr * 4)
                tp = parseFloat(p.entry_price) + (atr * 4)
              } else {
                sl = parseFloat(p.entry_price) + (atr * 4)
                tp = parseFloat(p.entry_price) - (atr * 4)
              }

              console.log('ENTRY', p.entry_price, 'SL', sl, 'TP', tp);

              const primitive = new PositionTool(
                {
                  entryPrice: parseFloat(p.entry_price),
                  stopLoss: sl,
                  takeProfit: tp,
                  entryTime: time1, closeTime: time2,
                  pnl: parseFloat(p.pnl),
                },
              )

              candlestickSeries.attachPrimitive(primitive);

              // Define the start and end timestamps in seconds


              // Add the two data points with the same price to create a line

              console.log('EXIT P', parseFloat(p.exit_price), time1, time2);

              
                const lineSeries = chart.addLineSeries({
                  color: 'black',
                  lineWidth: 1,
                  priceLineVisible: false,
                  // lineStyle: LineStyle.Dashed
                });

                lineSeries.setData([
                  { time: time1, value: parseFloat(p.exit_price) },
                  { time: time2, value: parseFloat(p.exit_price) },
                ]);

              
            }
          }
        }
      }


      let linedata_high: any = []
      let linedata_low: any = []
      let linedata_fast_high: any = []
      let linedata_fast_low: any = []

      const sma = (candles as any).wws1m

      console.log('SMA', sma);

      if (sma && sma.length > 0) {
        linedata_high = sma.map(row => ({ time: row.ts, value: parseFloat(row.sh) }))
        linedata_low = sma.map(row => ({ time: row.ts, value: parseFloat(row.sl) }))
        linedata_fast_high = sma.map(row => ({ time: row.ts, value: parseFloat(row.fh) }))
        linedata_fast_low = sma.map(row => ({ time: row.ts, value: parseFloat(row.fl) }))
      }

      chart.addLineSeries({
        color: '#ff040244',
        lineWidth: 2,
        priceLineVisible: false,
        lastValueVisible: false,
        priceLineSource: false,
        crossHairMarkerVisible: false,
        lineStyle: 0,
        lineType: 0,
      }).setData(linedata_high);

      chart.addLineSeries({
        color: '#ff040244',
        lineWidth: 2,
        priceLineVisible: false,
        lastValueVisible: false,
        priceLineSource: false,
        crossHairMarkerVisible: false,
        lineStyle: 0,
        lineType: 0,
      }).setData(linedata_low);

      chart.addLineSeries({
        color: '#0ca10944',
        lineWidth: 2,
        priceLineVisible: false,
        lastValueVisible: false,
        priceLineSource: false,
        crossHairMarkerVisible: false,
        lineStyle: 0,
        lineType: 0,
      }).setData(linedata_fast_high);

      chart.addLineSeries({
        color: '#0ca10944',
        lineWidth: 2,
        priceLineVisible: false,
        lastValueVisible: false,
        priceLineSource: false,
        crossHairMarkerVisible: false,
        lineStyle: 0,
        lineType: 0,
      }).setData(linedata_fast_low);

      // let linedata_high15m: any = []
      // let linedata_low15m: any = []
      // let linedata_fast_high15m: any = []
      // let linedata_fast_low15m: any = []

      // const sma15m = (candles as any).wws15m

      // console.log('SMA15', sma15m );

      // if (sma15m && sma15m.length > 0) {
      //   linedata_high15m = sma15m.map(row => ({ time: row.ts, value: parseFloat(row.sh) }))
      //   linedata_low15m = sma15m.map(row => ({ time: row.ts, value: parseFloat(row.sl) }))
      //   linedata_fast_high15m = sma15m.map(row => ({ time: row.ts, value: parseFloat(row.fh) }))
      //   linedata_fast_low15m = sma15m.map(row => ({ time: row.ts, value: parseFloat(row.fl) }))
      // }

      // chart.addLineSeries({
      //   color: '#ff040244',
      //   lineWidth: 2,
      //   priceLineVisible: false,
      //   lastValueVisible: false,
      //   priceLineSource: false,
      //   crossHairMarkerVisible: false,
      //   lineStyle: 0,
      //   lineType: 0,
      // }).setData(linedata_high15m);

      // chart.addLineSeries({
      //   color: '#ff040244',
      //   lineWidth: 2,
      //   priceLineVisible: false,
      //   lastValueVisible: false,
      //   priceLineSource: false,
      //   crossHairMarkerVisible: false,
      //   lineStyle: 0,
      //   lineType: 0,
      // }).setData(linedata_low15m);

      // chart.addLineSeries({
      //   color: '#0ca10944',
      //   lineWidth: 2,
      //   priceLineVisible: false,
      //   lastValueVisible: false,
      //   priceLineSource: false,
      //   crossHairMarkerVisible: false,
      //   lineStyle: 0,
      //   lineType: 0,
      // }).setData(linedata_fast_high15m);

      // chart.addLineSeries({
      //   color: '#0ca10944',
      //   lineWidth: 2,
      //   priceLineVisible: false,
      //   lastValueVisible: false,
      //   priceLineSource: false,
      //   crossHairMarkerVisible: false,
      //   lineStyle: 0,
      //   lineType: 0,
      // }).setData(linedata_fast_low15m);


    }
  }, [candles]);

  // useEffect(() => {
  //   candlestickSeries.update(lastCandle);
  // }, [lastCandle]);

  useEffect(() => {
    const handler = () => {
      chart.resize(chartRef.current.offsetWidth, HEIGHT);
    };
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  // GETTING CANDLES DATA

  useEffect(() => {
    async function fetchData() {

      const response = await fetch(`http://localhost:4000/api/get-candles-wws-positions?pair=${pair}`);
      let json = await response.json();
      console.log('json', json);
      setCandles(json)

    }
    fetchData();

  }, [pair])

  const scrollToSquare = (from: string, to: string, tp: string, sl: string) => {

    if (chart) {
      chart.timeScale().setVisibleRange({
        from: parseInt(from) - 10 * 60,
        to: parseInt(to) + 10 * 60,
      });

      console.log('PRICE SCALE', chart.priceScale());
    }
  };

  return (
    <>
      <div ref={chartRef} id='chart' style={{ 'position': 'relative', 'width': '100%', 'height': '500px' }}>
        <div
          ref={legendRef}
          style={{
            position: 'absolute',
            zIndex: 2,
            color: '#333',
            padding: 10,
            overflow: 'hidden',
          }}
        >
          {legend}
        </div>
      </div>

      <br />

      <table className="results-table">
        <tr>
          <th>Open</th>
          <th>Close</th>
          <th>Type</th>
          <th>Open price</th>
          <th>Close price</th>
          <th>PnL</th>
          <th>Equity</th>
          <th>Ribbon touch</th>
          <th>Slope</th>
          <th>Slope slow</th>
          <th>ATR</th>
          <th>SL distance</th>
        </tr>
        {(candles as any).positions && (candles as any).positions.map((p: any) => {

          const posDistance = p.type === 'long' ? (((p.tp - p.entryPrice) / p.entryPrice) * 100).toFixed(6)
            : (((p.entryPrice - p.tp) / p.entryPrice) * 100).toFixed(6)

          return (
            <tr key={p.openTimestamp} onClick={() => scrollToSquare(p.openTimestamp, p.closeTimestamp, p.tp, p.sl)}
              style={{ backgroundColor: parseFloat(p.pnl) > 0 ? '#0080006e' : '#ff000080' }}>

              <td className="date">{moment.unix(p.openTimestamp).format('HH:mm:ss DD-MM-YYYY')}</td>
              <td className="date">{moment.unix(p.closeTimestamp).format('HH:mm:ss DD-MM-YYYY')}</td>
              <td>{p.type}</td>
              <td>{p.entryPrice}</td>
              <td>{p.exitPrice}</td>
              <td>{p.pnl}</td>
              <td>{p.updatedAccountSize}</td>
              <td>{p.ribbonTouched}</td>
              <td>{(parseFloat(p.slope) * 10000000).toFixed(5)}</td>
              <td>{(parseFloat(p.slowSlope) * 10000000).toFixed(5)}</td>
              <td>{(parseFloat(p.atr) * 1000).toFixed(3)}</td>
              <td>{(parseFloat(p.slRatio) * 100).toFixed(2)}%</td>
            </tr>
            // <button key={p.openTimestamp} onClick={() => scrollToSquare(p.openTimestamp, p.closeTimestamp, p.tp, p.sl)}>Scroll to Square</button>
          )
        })}
      </table>
    </>
  );
};

ChartPositions.propTypes = {
  legend: PropTypes.string,
  initCandles: PropTypes.array,
  lastCandle: PropTypes.object,
  decimals: PropTypes.number,
};

export default ChartPositions;