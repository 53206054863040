import { AutoscaleInfo, Logical, Time, DataChangedScope } from 'lightweight-charts';
import {
	PositionToolPriceAxisPaneView,
	PositionToolTimeAxisPaneView,
} from './axis-pane-view';
import { PositionToolPriceAxisView, PositionToolTimeAxisView } from './axis-view';
import { Point, Position, PositionToolDataSource } from './data-source';
import { PositionToolOptions, defaultOptions } from './options';
import { PositionToolPaneView } from './pane-view';
import { PluginBase } from './plugin-base';


// {entryPrice: 0.16, stopLoss: 0.15, takeProfit: 0.175, entryTime: time1, closeTime: time2},
export class PositionTool
	extends PluginBase
	implements PositionToolDataSource
{
	_options: PositionToolOptions;
	_p1: Point;
	_p2: Point;
	position: Position;
	_paneViews: PositionToolPaneView[];
	_timeAxisViews: PositionToolTimeAxisView[];
	_priceAxisViews: PositionToolPriceAxisView[];
	_priceAxisPaneViews: PositionToolPriceAxisPaneView[];
	_timeAxisPaneViews: PositionToolTimeAxisPaneView[];


	constructor(
		position: Position,
		options: Partial<PositionToolOptions> = {}
	) {
		super();
		this.position = position

		this._options = {
			...defaultOptions,
			...options,
		};
		this._paneViews = [new PositionToolPaneView(this)];
		// this._timeAxisViews = [
		// 	new PositionToolTimeAxisView(this, p1),
		// 	new PositionToolTimeAxisView(this, p2),
		// ];
		// this._priceAxisViews = [
		// 	new PositionToolPriceAxisView(this, p1),
		// 	new PositionToolPriceAxisView(this, p2),
		// ];
		// this._priceAxisPaneViews = [new PositionToolPriceAxisPaneView(this, true)];
		// this._timeAxisPaneViews = [new PositionToolTimeAxisPaneView(this, false)];
	}

	updateAllViews() {
		//* Use this method to update any data required by the
		//* views to draw.
		this._paneViews.forEach(pw => pw.update());
		// this._timeAxisViews.forEach(pw => pw.update());
		// this._priceAxisViews.forEach(pw => pw.update());
		// this._priceAxisPaneViews.forEach(pw => pw.update());
		// this._timeAxisPaneViews.forEach(pw => pw.update());
	}

	// priceAxisViews() {
	// 	//* Labels rendered on the price scale
	// 	return this._priceAxisViews;
	// }

	// timeAxisViews() {
	// 	//* labels rendered on the time scale
	// 	return this._timeAxisViews;
	// }

	paneViews() {
		//* rendering on the main chart pane
		return this._paneViews;
	}

	// priceAxisPaneViews() {
	// 	//* rendering on the price scale
	// 	return this._priceAxisPaneViews;
	// }

	// timeAxisPaneViews() {
	// 	//* rendering on the time scale
	// 	return this._timeAxisPaneViews;
	// }

	autoscaleInfo(
		startTimePoint: Logical,
		endTimePoint: Logical
	): AutoscaleInfo | null {
		//* Use this method to provide autoscale information if your primitive
		//* should have the ability to remain in view automatically.
		if (
			this._timeCurrentlyVisible(this.position.entryTime, startTimePoint, endTimePoint) ||
			this._timeCurrentlyVisible(this.position.closeTime, startTimePoint, endTimePoint)
		) {
			return {
				priceRange: {
					minValue: Math.min(this.position.entryPrice, this.position.takeProfit),
					maxValue: Math.max(this.position.entryPrice, this.position.takeProfit),
				},
			};
		}
		return null;
	}

	dataUpdated(scope: DataChangedScope): void {
		//* This method will be called by PluginBase when the data on the
		//* series has changed.
	}

	_timeCurrentlyVisible(
		time: Time,
		startTimePoint: Logical,
		endTimePoint: Logical
	): boolean {
		const ts = this.chart.timeScale();
		const coordinate = ts.timeToCoordinate(time);
		if (coordinate === null) return false;
		const logical = ts.coordinateToLogical(coordinate);
		if (logical === null) return false;
		return logical <= endTimePoint && logical >= startTimePoint;
	}

	public get options(): PositionToolOptions {
		return this._options;
	}

	applyOptions(options: Partial<PositionToolOptions>) {
		this._options = { ...this._options, ...options };
		this.requestUpdate();
	}

	// public get p1(): Point {
	// 	return this._p1;
	// }

	// public get p2(): Point {
	// 	return this._p2;
	// }
}
