
import './App.css';

import SignalsReversal from './SignalsReversal';
import { SignalsReversalDataProvider } from './SignalsReversalDataProvider';

export default function SignalsReversalPage() {

  return (
    <div className="App">

        <SignalsReversalDataProvider>
            <SignalsReversal />
        </SignalsReversalDataProvider>

    </div>
  );
}