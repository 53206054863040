import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import moment from 'moment';
import styled from 'styled-components'
import { BrowserRouter } from "react-router-dom";
import { Card, Label } from './global/styles';

import Watchlist from './Watchlist';
import { PairDataProvider } from './PairDataProvider';
import WebsocketConsumer from './WebsocketConsumer';
import { SignalsDataProvider } from './SignalsDataProvider';
import Signals from './Signals';

export default function SignalsPage() {

  return (
    <div className="App">

        <SignalsDataProvider>
            <Signals />
        </SignalsDataProvider>

    </div>
  );
}