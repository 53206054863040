import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import ChartWrapper from './libs/react-lightweight-chart'
import moment from 'moment';
import styled from 'styled-components'
import { BrowserRouter } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

function BacktestPage() {
  const [candles, setCandles] = useState([])
  const [positions, setPositions] = useState([])
  const [trades, setTrades] = useState([])
  const [sma, setSMA] = useState([])
  const [from, setFrom] = useState(0)
  const [to, setTo] = useState(0)
  const [tradeClientId, setTradeClientId] = useState('')

  const options = {
    alignLabels: true,
    timeScale: {
      color: '#222',
      rightOffset: 12,
      barSpacing: 3,
      fixLeftEdge: true,
      lockVisibleTimeRangeOnResize: true,
      rightBarStaysOnScroll: true,
      borderVisible: true,
      borderColor: "#222222",
      visible: true,
      timeVisible: true,
      secondsVisible: true
    },
    layout: {
      backgroundColor: '#fff',
      textColor: 'rgba(1, 1, 1, 0.8)',
    },
    grid: {
      vertLines: {
        color: 'rgba(197, 203, 206, 0)',
      },
      horzLines: {
        color: 'rgba(197, 203, 206, 0.1)',
      },
    },
    crosshair: {
      // mode: LightweightCharts.CrosshairMode.Normal,
    },
  }

  useEffect(() => {
    async function fetchData() {
      const date = '2023-12-27 00:00:00'
      const ts = parseInt(moment(date).format('X'))
      const response = await fetch(`http://localhost:4000/api/get-candles-positions?ts=${ts}`)
      console.log('resp', response);

      let json = await response.json();
      setCandles(json.candles)
      setPositions(json.positions)
      setTrades(json.trades)
      setSMA(json.wilder)

      // const response2 = await fetch('http://localhost:4000/api/get-wilder')
      // let json2 = await response2.json();
      // console.log('json2', json2);
    }
    fetchData();

  }, [])

  let data: any = []

  if (candles && candles.length > 0) {

    data = candles.map(candle => {

      const time = parseInt(moment(candle['date']).format('X'))
      return { time, open: parseFloat(candle['open']), high: parseFloat(candle['high']), low: parseFloat(candle['low']), close: parseFloat(candle['close']) }
    })

    // if (data?.length > 0) {
    //   setFrom(data[0].time)
    //   setTo(data[data.length - 50].time)
    // }
  }

  let linedata_high: any = []
  let linedata_low: any = []
  let linedata_fast_high: any = []
  let linedata_fast_low: any = []

  if (sma && sma.length > 0) {
    linedata_high = sma.filter((row: any) => (row.input === 'high' && row.period === 144)).map(row => {
      const time = parseInt(moment(row['date']).format('X'))
      return { time, value: parseFloat(row['value']) }
    })
    linedata_low = sma.filter((row: any) => row.input === 'low' && row.period === 144).map(row => {
      const time = parseInt(moment(row['date']).format('X'))
      return { time, value: parseFloat(row['value']) }
    })

    linedata_fast_high = sma.filter((row: any) => (row.input === 'high' && row.period === 33)).map(row => {
      const time = parseInt(moment(row['date']).format('X'))
      return { time, value: parseFloat(row['value']) }
    })
    linedata_fast_low = sma.filter((row: any) => row.input === 'low' && row.period === 33).map(row => {
      const time = parseInt(moment(row['date']).format('X'))
      return { time, value: parseFloat(row['value']) }
    })
  }

  const markers: any = [];

  // const time = parseInt(moment('2023-01-01 00:00:00').format('X'))

  // markers.push({
  //   time,
  //   position: 'aboveBar',
  //   color: 'white',
  //   shape: 'arrowDown',
  // });

  // markers.push({
  //   time,
  //   position: 'belowBar',
  //   color: 'white',
  //   shape: 'arrowUp',
  // });

  let _positions = positions.map((p: any) => {
    const color = p.pnl > 0 ? 'green' : 'red'
    return {

      p1: { time: p.ts, price: p.open, direction: 'long', lineColor: color, labelBackgroundColor: '#fff', label: '' },
      p2: { time: p.closeTs, price: p.close, direction: 'long', label: parseFloat(p.pnl).toFixed(2) }
    }
  })

  function findEventCandleTs(tf: number, eventTs: number) {
    const tfSeconds = tf * 60
    const mod = eventTs % tfSeconds
    return eventTs - mod
  }


  let _trades = trades.map((t: any) => {
    let color = 'yellow'
    let direction = ''

    if (t.side === 'buy' && t.position_side === 'long') {
      direction = 'long'
      color = 'blue' // open position
    }

    if (t.side === 'buy' && t.position_side === 'short') {
      direction = 'long'

      if (t.realised_profit > 0) {
        color = 'green'
      } else {
        color = 'red'
      }
    }

    if (t.side === 'sell' && t.position_side === 'long') {
      direction = 'short'

      if (t.realised_profit > 0) {
        color = 'green'
      } else {
        color = 'red'
      }
    }

    if (t.side === 'sell' && t.position_side === 'short') {
      direction = 'short'
      color = 'blue' // open position
    }

    let active = false
    if (t.client_id === tradeClientId) {
      active = true
    }

    return {
      time: findEventCandleTs(5, t.ts),
      price: parseFloat(t.price),
      direction,
      color,
      labelBackgroundColor: '#fff',
      label: t.order_id,
      active
    }
  })

  let series = [
    {
      data,
      markers,
      positions: _positions,
      trades: _trades,
    }
  ]

  let lines = [
    {
      options: {
        color: '#f31616',
        lineWidth: 1,
        priceLineVisible: false,
        lastValueVisible: false,
      },
      data: linedata_high
    },
    {
      options: {
        color: '#f31616',
        lineWidth: 1,
        priceLineVisible: false,
        lastValueVisible: false,
      },
      data: linedata_low
    },
    {
      options: {
        color: '#2c62e1',
        lineWidth: 1,
        priceLineVisible: false,
        lastValueVisible: false,

      },
      data: linedata_fast_high
    },
    {
      options: {
        color: '#2c62e1',
        lineWidth: 1,
        priceLineVisible: false,
        lastValueVisible: false,
      },
      data: linedata_fast_low
    }
  ]

  function focusPosition(client_id: string, from: number, to: number) {
    setTradeClientId(client_id)
    setFrom(from)
    setTo(to)
  }

  return (
    <div className="App">

      <ChartPanel>
        {series[0]?.data?.length > 0 &&
          <ChartStyles>
            <ChartWrapper options={options}
              candlestickSeries={series}
              lineSeries={lines}
              autoWidth height={420}
              from={from}
              to={to}
            />
          </ChartStyles>
        }
      </ChartPanel>
      <TablePanel>
        {trades.length > 0 && <PositionsTableContainer>
          <Table>
            {
              trades.map((t: any) => {
                return <TableRow onClick={() => { focusPosition(t.client_id, parseInt(t.ts) - 8400, parseInt(t.ts) + 8400) }}>
                  <TableCell>{t.date}</TableCell>
                  <TableCell>{parseFloat(t.price).toFixed(2)}</TableCell>
                  <TableCell>PNL: {parseFloat(t.realised_profit).toFixed(2)}</TableCell>
                  <TableCell>{t.amount}</TableCell>
                  <TableCell>{t.client_id}</TableCell>
                </TableRow>
              })
            }
          </Table>
        </PositionsTableContainer>
        }


        {positions.length > 0 && <PositionsTableContainer>
          <Table>
            {
              positions.map((p: any) => {
                return <TableRow onClick={() => { focusPosition('0', parseInt(p.ts) - 4200, parseInt(p.closeTs) + 4200) }}>
                  <TableCell>{p.date}</TableCell>
                  <TableCell>{parseFloat(p.open).toFixed(2)}</TableCell>
                  <TableCell>{p.closeDate}</TableCell>
                  <TableCell>PNL: {parseFloat(p.pnl).toFixed(2)}</TableCell>
                  <TableCell>MAX PNL: {parseFloat(p.maxPnl).toFixed(2)}</TableCell>
                </TableRow>
              })
            }
          </Table>
        </PositionsTableContainer>
        }
      </TablePanel>

    </div>
  );
}


const Directions = styled.div`
text-align: left;
padding: 30px;
`

const DirectionsRow = styled.div`
display: flex;
`
const DirectionsPair = styled.div`
 width: 200px;
`
const DirectionsDir = styled.div`
 width: 100px;
`

const ChartPanel = styled.div`
  width: 100%;
  height: 50vh;
`
const TablePanel = styled.div`
  width: 100%;
  height: 50vh;
  overflow: scroll;
`



const ChartStyles = styled.div`
  width: 1000px;
  height: 400px;
  margin: 20px auto;
`

const PositionsTableContainer = styled.div`
  width: 800px;
  margin: 0 auto;
`


export default BacktestPage;
