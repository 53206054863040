import React, { useEffect, useRef, useState } from "react";

import { map, distinctUntilChanged, tap, filter, pairwise } from 'rxjs/operators';

import moment from "moment";

import styled from "styled-components";
import ChartWrapper from "./libs/react-lightweight-chart";
import { formatMoney } from "./WebsocketConsumer";
import { Signals15mDataContext } from "./Signals15mDataProvider";


// momentjs calculate minutes from a date
function minutesFromNow(date: any) {
  return moment().diff(date, 'minutes');
}
const SignalItem: React.FC<any> = ({ pairs, signal }) => {

  const pair = pairs[signal.pair];

  const background = signal.htf_event === 'touch slow from bottom' ? '#4fc70e5c' : '#fff';
  const volumeFilter = pair.vol_5m > 500000 ? 'flex' : 'none';


  const pairLinkTv = `https://www.tradingview.com/chart/?symbol=BINANCE%3A${signal.pair.replace('/', '')}.P`
  const pairLinkBinance = `https://www.binance.com/en/futures/${signal.pair.replace('/', '')}`

  return (
    <div className="signal-item" style={{ display: volumeFilter, backgroundColor: background }}>
      <div style={{ width: '200px' }} >

        <h3>
          <a href={pairLinkTv} target="_blank">{signal.pair}
          </a>&nbsp;&nbsp;&nbsp;<a href={pairLinkBinance} target="_blank">🔶</a>
        </h3>
      </div>
      <div style={{ width: '300px' }} >{signal.interval}m  {signal.content}</div>
      <div style={{ width: '300px' }} >{minutesFromNow(signal.add_date)} minutes ago</div>
      <div style={{ width: '300px' }} >m1: {pair.m1_cross}, m15: {pair.m15_cross}</div>
      <div style={{ width: '300px' }} >{formatMoney(pair.vol_5m)}</div>

    </div>
  )
}

const Signals15m: React.FC = () => {
  const [data, setData] = useState<any>(null);
  const [signalsData, setSignalsData] = useState<any>(null);
  const { pairs$, signals$ } = React.useContext(Signals15mDataContext);

  useEffect(() => {

    const subscription = pairs$.pipe(
      map((pairs: any) => {
        console.log('pairs', pairs);

        const pairsMap = {}
        pairs.forEach((pair: any) => {
          pairsMap[pair.pair] = pair;
        })

        console.log('PAIRS MAP', pairsMap);

        return pairsMap
      }),
      // distinctUntilChanged(_.isEqual),
    ).subscribe((value: any) => {

      setData(value);
    });
    const subscriptionSignals = signals$.pipe(
      // map((signals: any) => {
      //   console.log('SIGNALS', signals );


      //   return signals
      // }),
      // distinctUntilChanged(_.isEqual),
    ).subscribe((value: any) => {

      console.log('VALUE SUB', value);

      setSignalsData(value);
    });

    return () => {
      subscription.unsubscribe();
      subscriptionSignals.unsubscribe();
    };
  }, [pairs$, signals$]);


  useEffect(() => {

  }, []);

  return (
    <>
      <div className="">
        <header className="header">
          <div className="filters">
            filters
          </div>
        </header>
        <div className="content">
          {
            signalsData && data
              ? <>

                <div className="signals-list">
                  {
                    signalsData.map((signal: any) => {


                      return <SignalItem key={signal.id} pairs={data} signal={signal} ></SignalItem>


                    })
                  }
                </div>
              </>
              : (<div>loading...</div>)
          }
        </div>
      </div>
    </>
  );
};

export default Signals15m;
