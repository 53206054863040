import { Coordinate, ISeriesPrimitivePaneView } from 'lightweight-charts';
import { PositionToolPaneRenderer } from './pane-renderer';
import { Position, PositionToolDataSource } from './data-source';

export interface ViewPoint {
	x: Coordinate | null;
	y: Coordinate | null;
}

export class PositionToolPaneView implements ISeriesPrimitivePaneView {
	_source: PositionToolDataSource;
	_position: Position;
	_p1: ViewPoint = { x: null, y: null };
	_p2: ViewPoint = { x: null, y: null };
	_p3: ViewPoint = { x: null, y: null };
	_winner: boolean = false;

	constructor(source: PositionToolDataSource) {
		this._source = source;
	}

	update() {		
		const series = this._source.series;
		const y1 = series.priceToCoordinate(this._source.position.entryPrice);
		const y2 = series.priceToCoordinate(this._source.position.takeProfit);
		const y3 = series.priceToCoordinate(this._source.position.stopLoss);
		const timeScale = this._source.chart.timeScale();
		const x1 = timeScale.timeToCoordinate(this._source.position.entryTime);
		const x2 = timeScale.timeToCoordinate(this._source.position.closeTime);
		this._p1 = { x: x1, y: y1 };
		this._p2 = { x: x2, y: y2 };
		this._p3 = { x: x2, y: y3 };
		this._winner = this._source.position.pnl > 0;
	}
	

	renderer() {		
		return new PositionToolPaneRenderer(
			this._p1,
			this._p2,
			this._p3,
			this._winner ? this._source.options.fillColorWin : this._source.options.fillColorTP,
			this._winner ? this._source.options.fillColorSL : this._source.options.fillColorLost,
		);
	}
}
