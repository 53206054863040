// create react component

import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';

function Menu() {

    return (
        <div className="top-bar-wrapper">
            <div className="logo">LumosData</div>
            <nav>
                <ul className="menu">
                    <li className="menu-item">
                        <a href="/" className="menu-link">Screener</a>
                    </li>
                    <li className="menu-item">
                        <a href="/alerts" className="menu-link">Alerts</a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Menu