import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";

import _, { lte, set } from 'lodash';

import { CrosshairMode } from 'lightweight-charts';


import { map, distinctUntilChanged, tap, filter, pairwise } from 'rxjs/operators';

import moment from "moment";

import { WatchlistDataContext } from "./WatchlistDataProvider";
import styled from "styled-components";
import ChartWrapper from "./libs/react-lightweight-chart";

export function compareObjects(a: any, b: any): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}

function requestNotificationPermission() {

  if ('Notification' in window) {
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // Now you can show notifications
      } else {
        console.log("Notification permission denied.");
      }
    });
  }
}

const chartOptions = {
  alignLabels: true,
  timeScale: {
    color: '#ff9e9e',
    rightOffset: 12,
    barSpacing: 3,
    fixLeftEdge: true,
    lockVisibleTimeRangeOnResize: true,
    rightBarStaysOnScroll: true,
    borderVisible: true,
    borderColor: "#fff000",
    visible: true,
    timeVisible: true,
    secondsVisible: true,
  },
  layout: {
    backgroundColor: '#fff',
    textColor: 'rgba(255, 255, 255, 0.8)',
  },
  grid: {
    vertLines: {
      color: 'rgba(197, 203, 206, 0)',
    },
    horzLines: {
      color: 'rgba(197, 203, 206, 0.1)',
    },
  },
  crosshair: {
    mode: CrosshairMode.Normal,
  },
}

const PairChart: React.FC<any> = ({ pair, data }) => {

  const { pairs$ } = React.useContext(WatchlistDataContext);
  const [pairData, setPairData] = useState<any>(null);

  useEffect(() => {
    const subscription = pairs$.pipe(
      map((pairs: any) => {
        return pairs[pair]
      }),
    ).subscribe((value: any) => {
      setPairData(value)
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [pairs$]);


  let series = []
  let lastItemTs = 0
  let firstItemTs = 0
  let myLevels = []
  let myOrders = []

  if (pairData && pairData.candles) {
    let data: any = []
    const { candles } = pairData;

    lastItemTs = candles[candles.length - 1].ts
    firstItemTs = candles[0].ts

    if (candles && candles.length > 0) {
      data = candles.map(candle => {
        const time = parseInt(moment(candle['date']).format('X'))
        return { time, open: parseFloat(candle['open']), high: parseFloat(candle['high']), low: parseFloat(candle['low']), close: parseFloat(candle['close']) }
      })
    }

    series = [
      {
        data,
      }
    ]
  }

  if (pairData && pairData.levels) {
    const { levels, orders } = pairData;

    console.log('MY LEVELS', levels );
    console.log('MY ORDERS', orders );

    myLevels = levels.map((level: any) => {
      return  ({
        options: {
          id: `level-${level.id}`,
          color: '#2c62e1',
          lineWidth: 2,
          lineColor: '#2c62e1',
          lastValueVisible: false,
          priceLineVisible: false,
          crossHairMarkerVisible: true
        },
        data: [
          { time: level.ts, value: parseFloat(level.value) },
          { time: lastItemTs, value: parseFloat(level.value) },
        ],
        markers: [
          { id: `level-m-${level.id}`,time: level.ts, position: 'aboveBar', color: '#2c62e1', shape: 'arrowDown', text: 'L'}
        ]
      })
    })

    myOrders = orders.map((order: any) => {
      return  ({
        options: {
          id: `order-${order.id}`,
          color: '#23a83f',
          lineWidth: 1,
          lineColor: '#23a83f',
          lastValueVisible: false,
          priceLineVisible: false,
          crossHairMarkerVisible: true
        },
        data: [
          { time: firstItemTs, value: parseFloat(order.price) },
          { time: lastItemTs, value: parseFloat(order.price) },
        ],
        // markers: [
        //   { id: `order-m-${order.id}`,time: order.ts, position: 'aboveBar', color: '#2c62e1', shape: 'arrowDown', text: 'O'}
        // ]
      })
    })
    
  }

  let lines = []

  if (pairData && pairData.averages) {
    const { averages: sma } = pairData;

    let linedata_high: any = []
    let linedata_low: any = []
    let linedata_fast_high: any = []
    let linedata_fast_low: any = []

    if (sma && sma.length > 0) {
      linedata_high = sma.filter((row: any) => (row.input === 'high' && row.period === 144)).map(row => {
        const time = parseInt(moment(row['date']).format('X'))
        return { time, value: parseFloat(row['value']) }
      })
      linedata_low = sma.filter((row: any) => row.input === 'low' && row.period === 144).map(row => {
        const time = parseInt(moment(row['date']).format('X'))
        return { time, value: parseFloat(row['value']) }
      })

      linedata_fast_high = sma.filter((row: any) => (row.input === 'high' && row.period === 33)).map(row => {
        const time = parseInt(moment(row['date']).format('X'))
        return { time, value: parseFloat(row['value']) }
      })
      linedata_fast_low = sma.filter((row: any) => row.input === 'low' && row.period === 33).map(row => {
        const time = parseInt(moment(row['date']).format('X'))
        return { time, value: parseFloat(row['value']) }
      })

      lines = [
        {
          options: {
            color: '#f31616',
            lineWidth: 1,
            lineColor: '#f31616',
            lastValueVisible: false,
            priceLineVisible: false,
            crosshairMarkerVisible: false,
          },
          data: linedata_high
        },
        {
          options: {
            color: '#f31616',
            lineWidth: 1,
            lineColor: '#f31616',
            lastValueVisible: false,
            priceLineVisible: false,
            crosshairMarkerVisible: false
          },
          data: linedata_low
        },
        {
          options: {
            color: '#2c62e1',
            lineWidth: 1,
            lineColor: '#2c62e1',
            lastValueVisible: false,
            priceLineVisible: false,
            crosshairMarkerVisible: false
          },
          data: linedata_fast_high
        },
        {
          options: {
            color: '#2c62e1',
            lineWidth: 1,
            lineColor: '#2c62e1',
            lastValueVisible: false,
            priceLineVisible: false,
            crosshairMarkerVisible: false
          },
          data: linedata_fast_low
        }
      ]
    }
  }

  async function onPriceLineAdded(params: any) {
    console.log('PARAMS on line added', params);

    // ajax post request using fetch that will send post request to the server endpoint /api/save-level with the data in the body using await
    const response = await fetch('http://localhost:4000/api/save-level', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        pair: pair,
        ts: params.time,
        value: params.price
      }),
    });

    console.log('response', response );
  
  }

  async function onPriceLineRemoved(params: any) {
    console.log('PARAMS on line removed', params);

    const response = await fetch('http://localhost:4000/api/delete-level', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        pair: pair,
        id: params.id,
      }),
    });

    console.log('response', response );
  
  }
  
  lines = [...lines, ...myLevels, ...myOrders]

  return (
    <div>
      {series[0]?.data?.length > 0 &&
        <ChartBox>
          <ChartWrapper options={chartOptions}
            candlestickSeries={series}
            lineSeries={lines}
            autoWidth height={420} 
            callbacks={{ onPriceLineAdded, onPriceLineRemoved}}
            />
        </ChartBox>
      }
    </div>
  );
}


const Watchlist: React.FC = () => {
  const [data, setData] = useState<any>(null);
  const { pairs$ } = React.useContext(WatchlistDataContext);

  useEffect(() => {

    const subscription = pairs$.pipe(
      map((pairs: any) => {
        return Object.keys(pairs)
      }),
      // distinctUntilChanged(_.isEqual),
    ).subscribe((value: any) => {

      setData(value);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [pairs$]);


  useEffect(() => {
    requestNotificationPermission();
  }, []);

  return (
    <>
      <div className="">
        <header className="header">
          <div className="filters">
            filters
          </div>
        </header>
        <div className="content">
          {data ? (<>

            <div className="watchlist-container">
              {
                data.map((pair: any) => {
                  console.log('DATA', pair);

                  return <div className="watchlist-box">
                    <div className="box-content">
                      <h3>{pair}</h3>
                      {/* chart dynamic sizing https://github.com/Kaktana/kaktana-react-lightweight-charts/issues/26  */}
                      <PairChart key={pair} pair={pair} data={data[pair]}></PairChart>
                    </div>
                  </div>

                })
              }

            </div>

          </>
          ) : (<div>loading...</div>)
          }
        </div>
      </div>
    </>
  );
};

export default Watchlist;



const ChartBox = styled.div`
height: 400px;
margin: 10px auto;
`
