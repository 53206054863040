import React, { useEffect, useRef, useState } from "react";

import { map, distinctUntilChanged, tap, filter, pairwise } from 'rxjs/operators';

import moment from "moment";

import styled from "styled-components";
import ChartWrapper from "./libs/react-lightweight-chart";
import { formatMoney } from "./WebsocketConsumer";
import { SignalsReversalDataContext } from "./SignalsReversalDataProvider";
import { Tooltip } from "@mui/material";
import { convertMinutesToHours, getDelayedInfo, getDirectionIcon } from "./utils";


// momentjs calculate minutes from a date
function minutesFromNow(date: any) {
  return moment().diff(date, 'minutes');
}

function getWWSDirections(pair: any) {
  
  const wws1m = JSON.parse(pair.wws1m);
  const wws5m = JSON.parse(pair.wws5m);
  const wws15m = JSON.parse(pair.wws15m);

  return <>
  {wws1m && wws1m.dir && <div>1m trend:  {wws1m.dir}</div>}
  {wws5m && wws5m.dir &&<div>5m trend:  {wws5m.dir}</div>}
  {wws15m && wws15m.dir &&<div>15m trend:  {wws15m.dir}</div>}
  </>
}
const SignalItem: React.FC<any> = ({ pairs, signal }) => {

  const pair = pairs[signal.pair];
  
  console.log('PAIR', pair );

  const wws1m = JSON.parse(pair.wws1m)
    const wws5m = JSON.parse(pair.wws5m)
    const wws15m = JSON.parse(pair.wws15m)
    const wws1h = JSON.parse(pair.wws1h)
    const wws4h = JSON.parse(pair.wws4h)


    
    
    const stats1m = pair.stats1m ? JSON.parse(pair.stats1m) : undefined
    const stats5m = pair.stats5m ? JSON.parse(pair.stats5m) : undefined
    const stats15m = pair.stats15m ? JSON.parse(pair.stats15m) : undefined


  // console.log('stats 1m', stats1m.currDirection );
  
  if(!pair) {
    return null;
  }
  
  const background = signal.htf_event === 'touch slow from bottom' ? '#4fc70e5c' : '#fff';
  const volumeFilter = pair.vol_5m && pair.vol_5m > 500000 ? 'flex' : 'none';

  const pairLinkTv = `https://www.tradingview.com/chart/?symbol=BINANCE%3A${signal.pair.replace('/', '')}.P`
  const pairLinkBinance = `https://www.binance.com/en/futures/${signal.pair.replace('/', '')}`

  return (
    <div className="signal-item" style={{ display: volumeFilter, backgroundColor: background }}>
      <div style={{ width: '140px', flexGrow: '1' }} >

        <h3>
          <a href={pairLinkTv} target="_blank">{signal.pair}
          </a>&nbsp;<a href={pairLinkBinance} target="_blank">🔶</a>
        </h3>
      </div>
      <div style={{ flexGrow: '1' }} >
      <Tooltip title={getWWSDirections(pair)}>
        <div>
          <div>{signal.interval}m  {signal.content}</div>
          <div>trend duration: {stats1m && stats1m.duration ? convertMinutesToHours(stats1m.duration) : ''}</div>
          <div>
             {getDirectionIcon(wws1m.dir, getDelayedInfo(wws1m.ts, 1), 10)} &nbsp;&nbsp;
             {getDirectionIcon(wws5m.dir, getDelayedInfo(wws5m.ts, 5), 10)} &nbsp;&nbsp;
             {getDirectionIcon(wws15m.dir, getDelayedInfo(wws15m.ts, 15), 10)} &nbsp;&nbsp;
             {getDirectionIcon(wws1h.dir, getDelayedInfo(wws1h.ts, 60), 10)} &nbsp;&nbsp;
             {getDirectionIcon(wws4h.dir, getDelayedInfo(wws4h.ts, 240), 10)} &nbsp;&nbsp;
             </div>
        </div>
        </Tooltip>
        </div>
      <div style={{  flexGrow: '1' }} >{minutesFromNow(signal.event_date)} min ({signal.event_date}) </div>
      <div style={{textAlign: 'right', flexGrow: '1' }}> {formatMoney(pair.vol_5m)}</div>
    </div>
  )
}

const SignalsReversal: React.FC = () => {
  const [data, setData] = useState<any>(null);
  const [signalsData, setSignalsData] = useState<any>(null);
  const { pairs$, signals$ } = React.useContext(SignalsReversalDataContext);

  useEffect(() => {

    const subscription = pairs$.pipe(
      map((pairs: any) => {
        console.log('pairs', pairs);

        const pairsMap = {}
        pairs.forEach((pair: any) => {
          pairsMap[pair.pair] = pair;
        })

        console.log('PAIRS MAP', pairsMap);

        return pairsMap
      }),
      // distinctUntilChanged(_.isEqual),
    ).subscribe((value: any) => {      
      setData(value);
    });
    const subscriptionSignals = signals$.pipe(
      // map((signals: any) => {
      //   console.log('SIGNALS', signals );


      //   return signals
      // }),
      // distinctUntilChanged(_.isEqual),
    ).subscribe((value: any) => {

      setSignalsData(value);
    });

    return () => {
      subscription.unsubscribe();
      subscriptionSignals.unsubscribe();
    };
  }, [pairs$, signals$]);


  useEffect(() => {

  }, []);

  return (
    <>
      <div className="">
        <header className="header">
          <div className="filters">
            filters
          </div>
        </header>
        <div className="content">
          {
            signalsData && data
              ? <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                
                <div className="signals-list">
                  {
                    signalsData.filter((signal:any) => {
                      return signal.interval === '15' && ((signal.content === 'closed above fast' && signal.hts_direction === 'down') || (signal.content === 'closed below fast' && signal.hts_direction === 'up'))
                    } ).map((signal: any) => {
                      return <SignalItem key={signal.id} pairs={data} signal={signal} ></SignalItem>
                    })
                  }
                </div>
                <div className="signals-list">
                  {
                    signalsData.filter((signal:any) => {                      
                      return signal.interval === '1' && ((signal.content === 'closed above fast' && signal.hts_direction === 'down') || (signal.content === 'closed below fast' && signal.hts_direction === 'up'))
                    } ).map((signal: any) => {
                      return <SignalItem key={signal.id} pairs={data} signal={signal} ></SignalItem>
                    })
                  }
                </div>
                </div>
              </>
              : (<div>loading...</div>)
          }
        </div>
      </div>
    </>
  );
};

export default SignalsReversal;
