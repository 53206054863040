import React, { useEffect, useState } from 'react';
import './App.css';
import moment from 'moment';
import { AppConfig, setConfig } from './configManager';
import { useConfig } from './useConfig';
import './AlertsForm.css';
import { formatNumberK } from './utils';


const FormWithLocalStorage = () => {
  const [showNotification, setShowNotification] = useState(false);
  const config = useConfig();

  const [formState, setFormState] = useState({
    percentDistance: '',
    minVolume: '',
    active: false,
  });

  // Load form state from localStorage when component mounts
  useEffect(() => {
    const savedFormState = config.alerts
    if (savedFormState) {
      setFormState(config.alerts);
    }
  }, []);

  // Save form state to localStorage
  const handleSave = () => {
    setConfig(
      {
        alerts: {
          percentDistance: formState.percentDistance,
          minVolume: formState.minVolume,
          active: formState.active,
        }
      }
    )
    setShowNotification(true);

  };

  // Handle changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  // Handle toggle change (checkbox)
  const handleToggleChange = () => {
    setFormState({
      ...formState,
      active: !formState.active,
    });
  };

  useEffect(() => {
    let timer;
    if (showNotification) {
      timer = setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showNotification]);

  return (
    <div className="alerts-form">
      <div className="form-container">
        <form>
          <div className="form-group">
            <label>PDH/PDL percent distance:</label>
            <input
              type="number"
              name="percentDistance"
              value={formState.percentDistance}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Min volume 5min:</label>
            <input
              type="text"
              name="minVolume"
              value={formState.minVolume}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={formState.active}
                onChange={handleToggleChange}
              />
              Alerts Active
            </label>
          </div>
          <button type="button" onClick={handleSave}>
            Save
          </button>
          <div className={`notification ${showNotification ? 'show' : ''}`}>
            Save successful!
          </div>
        </form>
      </div>
    </div>
  );
};

const AlertsHistory = () => {
  const [data, setData] = useState([]);

  const formatDate = (ts) => {
    return moment.unix(ts).format('YYYY-MM-DD HH:mm:ss');
  };

  // Load data from localStorage when component mounts
  useEffect(() => {
    const savedData = localStorage.getItem('displayedNotifications');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      //reverse the order of parsedData array

      const values = Object.entries(parsedData).reverse()
      setData(values);
    }
  }, []);

  return (
    <>
      <div style={{ maxWidth: '900px', margin: '0 auto', border: '1px solid #e5e5e5' }}>
        <table>
          <thead>
            <tr>
              <th>Date time</th>
              <th>Pair</th>
              <th>Volume 5m</th>
            </tr>
          </thead>
          <tbody>
            {data.map(([pair, values]) => (
              <tr key={pair}>
                <td>{formatDate(values.ts)}</td>
                <td>{pair}</td>
                <td>{formatNumberK(values.extra.vol_5m)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>

  );
};

export default function AlertsPage() {

  return (
    <div className="App">
      <FormWithLocalStorage />
      <br />
      <h3>Alerts History</h3>
      <br />
      <AlertsHistory />
    </div>
  );
}