import React, { useEffect, useRef, useState, createContext } from "react";
import io from "socket.io-client";

import { fromEvent, BehaviorSubject, Subject } from 'rxjs';
import { map, distinctUntilChanged, shareReplay } from 'rxjs/operators';

const WatchlistDataContext = createContext({
  pairs$: null,
})

const SOCKET_SERVER_URL = "http://localhost:3333"; // replace with your server URL
// const SOCKET_SERVER_URL = "http://188.166.4.252:3333"; // replace with your server URL

function WatchlistDataProvider({ children }: any) {

  const $pairsSubject: any = new Subject();

  useEffect(() => {
  
    const socket = io(SOCKET_SERVER_URL, {
      reconnectionAttempts: 10, // Number of reconnection attempts before giving up
      reconnectionDelay: 30000, // Delay between reconnection attempts
    });

    socket.emit("subscribe-watchlist");

    socket.on("watchlist-pairs", (data: any) => {

      // console.log('data', data );
      
      const pairs = JSON.parse(data)
      
      if (Object.keys(pairs).length > 0) {
       
        console.log('EMMITING PAIRS',);

        $pairsSubject.next(pairs);
      }
    });

    return () => {
      socket.disconnect(); // Disconnect from the socket.io server when the component unmounts
    };
  }, []);

  const pairs$ = $pairsSubject.pipe(shareReplay(1))


  return (
    <WatchlistDataContext.Provider value={{pairs$}}>
      {children}
    </WatchlistDataContext.Provider>
  );
}

export { WatchlistDataContext, WatchlistDataProvider };