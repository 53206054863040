import { CanvasRenderingTarget2D } from 'fancy-canvas';
import { ISeriesPrimitivePaneRenderer } from 'lightweight-charts';
import { ViewPoint } from './pane-view';
import { positionsBox } from './helpers/dimensions/positions';

export class PositionToolPaneRenderer implements ISeriesPrimitivePaneRenderer {
	_p1: ViewPoint;
	_p2: ViewPoint;
	_p3: ViewPoint;
	_fillColorTP: string;
	_fillColorSL: string;

	constructor(p1: ViewPoint, p2: ViewPoint, p3: ViewPoint, fillColorTP: string, fillColorSL: string) {
		this._p1 = p1;
		this._p2 = p2;
		this._p3 = p3;
		this._fillColorTP = fillColorTP;
		this._fillColorSL = fillColorSL;
	}

	draw(target: CanvasRenderingTarget2D) {
		target.useBitmapCoordinateSpace(scope => {
			if (
				this._p1.x === null ||
				this._p1.y === null ||
				this._p2.x === null ||
				this._p2.y === null ||
				this._p3.y === null ||
				this._p3.y === null
			)
				return;
			const ctx = scope.context;
			const horizontalPositions = positionsBox(
				this._p1.x,
				this._p2.x,
				scope.horizontalPixelRatio
			);
			const verticalPositions = positionsBox(
				this._p1.y,
				this._p2.y,
				scope.verticalPixelRatio
			);
			ctx.fillStyle = this._fillColorTP;
			ctx.fillRect(
				horizontalPositions.position,
				verticalPositions.position,
				horizontalPositions.length,
				verticalPositions.length
			);

			const horizontalPositions2 = positionsBox(
				this._p1.x,
				this._p3.x,
				scope.horizontalPixelRatio
			);
			const verticalPositions2 = positionsBox(
				this._p1.y,
				this._p3.y,
				scope.verticalPixelRatio
			);
			ctx.fillStyle = this._fillColorSL;
			ctx.fillRect(
				horizontalPositions2.position,
				verticalPositions2.position,
				horizontalPositions2.length,
				verticalPositions2.length
			);
		});
	}
}
