import React, { useEffect, useRef, useState, createContext, useCallback } from "react";
import io, { Socket } from "socket.io-client";

import { fromEvent, BehaviorSubject, Subject } from 'rxjs';
import { map, distinctUntilChanged, shareReplay } from 'rxjs/operators';

import moment from 'moment';
import { set } from "lodash";

const PairsDataContext = createContext({
  pairs$: null,
  filter: 'vol_5m > 0',
  changeFilter: (filter: string) => { },
  manualReconnect: () => { }
})

const SOCKET_SERVER_URL = process.env.REACT_APP_WS_URL ? process.env.REACT_APP_WS_URL : ''

let isConnected = false;
const sockets: any = new Map();
const $pairsSubject: any = new Subject();

function PairDataProvider({ children }: any) {

  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [reconnectionAttempts, setReconnectionAttempts] = useState(0);
  const [lastPong, setLastPong] = useState<string | null>(null);
  const [filter, setFilter] = useState('vol_5m > 500000');

  const connectSocket = useCallback(() => {
    const newSocket = io(SOCKET_SERVER_URL, {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
    });

    newSocket.on('connect', () => {
      console.log('Connected to server');
      setIsConnected(true);
      setReconnectionAttempts(0);
      newSocket.emit("subscribe", JSON.stringify({ filter }));
    });

    newSocket.on('disconnect', (reason) => {
      console.log('Disconnected:', reason);
      setIsConnected(false);
    });

    newSocket.on('reconnect_attempt', (attemptNumber) => {
      console.log(`Reconnection attempt ${attemptNumber}`);
      setReconnectionAttempts(attemptNumber);
    });

    newSocket.on('reconnect_failed', () => {
      console.log('Reconnection failed');
    });

    newSocket.on('reconnect', (attemptNumber) => {
      console.log('Reconnected on attempt:', attemptNumber);
    });


    newSocket.on('reconnect_error', (error) => {
      console.error('Reconnection error:', error);
    });

    newSocket.on('reconnect_failed', () => {
      console.error('Failed to reconnect');
      // You might want to notify the user or trigger a manual reconnect here
    });

    newSocket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    newSocket.on('pong', () => {
      setLastPong(new Date().toISOString());
    });

    newSocket.on("pairs-status", (data: any) => {
      const dataParsed = JSON.parse(data)      
      if (dataParsed) {                
        $pairsSubject.next(dataParsed);
      }
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [filter]);

  useEffect(() => {
    const cleanup = connectSocket();
    return cleanup;
  }, [connectSocket]);

  const manualReconnect = useCallback(() => {
    console.log('reconnecting manually', );
    
    if (socket) {
      socket.disconnect();
    }
    connectSocket();
  }, [socket, connectSocket]);

  const sendPing = useCallback(() => {
    if (socket) {
      socket.emit('ping');
    }
  }, [socket]);

  const pairs$ = $pairsSubject.pipe(shareReplay(1))

  const changeFilter = (filter: string) => {
    setFilter(filter);
  }

  return (
    <PairsDataContext.Provider value={{ pairs$, filter, changeFilter, manualReconnect }}>
      {children}
    </PairsDataContext.Provider>
  );
}

export { PairsDataContext, PairDataProvider };