import './App.css';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { GlobalStyle } from './global/GlobalStyle';
import styled from 'styled-components';

import StocksListPage from './StocksList';
import BacktestPage from './BacktestPage';
import WatchlistPage from './WatchlistPage';
import SignalsPage from './SignalsPage';
import Signals15mPage from './SignalsPage15m';
import SignalsReversalPage from './SignalsReversalPage';
import AlertsPage from './AlertsPage';
import { NotificationComponent } from './AlertsNotifications';
import { AlertsDataProvider } from './AlertsDataProvier';
import Menu from './Menu';
import ChartPage from './Chart';
import ChartPositions from './ChartPositions';
import ChartSMC from './ChartPositionsSMC';

function App() {
  return (
    <AppContainer>
      <GlobalStyle />
      <AlertsDataProvider>
        <NotificationComponent />
      </AlertsDataProvider>
      <Menu/>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<DirectionsPage/>} /> */}
          <Route path="/" element={<StocksListPage/>} />
          {/* <Route path="/chart/:id" element={<ChartPage />} /> */}
          <Route path="/chart/:pair" element={<div><ChartPage/></div>} />
          <Route path="/chart-positions/:pair" element={<div><ChartPositions/></div>} />
          <Route path="/chart-smc/:pair" element={<div><ChartSMC/></div>} />
          <Route path="/backtest/" element={<div><BacktestPage/></div>} />
          <Route path="/watchlist/" element={<div><WatchlistPage/></div>} />
          <Route path="/signals/" element={<div><SignalsPage/></div>} />
          <Route path="/signals15m/" element={<div><Signals15mPage/></div>} />
          <Route path="/signals-reversal/" element={<div><SignalsReversalPage/></div>} />
          <Route path="/alerts/" element={<div><AlertsPage/></div>} />
        </Routes>
      </BrowserRouter>
    </AppContainer>
  );
}

const AppContainer = styled.div`
  overflow: hidden;
  color: #343434;
`


export default App;
