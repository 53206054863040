import React, { useEffect, useRef, useState, createContext } from "react";
import io from "socket.io-client";

import { Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

const Signals15mDataContext = createContext({
  pairs$: null,
  signals$: null
})

const SOCKET_SERVER_URL = "http://localhost:3333"; // replace with your server URL
// const SOCKET_SERVER_URL = "http://188.166.4.252:3333"; // replace with your server URL

function Signals15mDataProvider({ children }: any) {

  const [filter, setFilter] = useState('vol_5m > 0');

  const $pairsSubject: any = new Subject();
  const $signalsSubject: any = new Subject();

  useEffect(() => {
  
    const socket = io(SOCKET_SERVER_URL, {
      reconnectionAttempts: 10, // Number of reconnection attempts before giving up
      reconnectionDelay: 30000, // Delay between reconnection attempts
    });

    socket.emit("subscribe", JSON.stringify({filter}));    
    socket.on("pairs-status", (data: any) => {

      console.log('data', data );
      
      const pairs = JSON.parse(data)
      
      if (Object.keys(pairs).length > 0) {
        console.log('EMMITING PAIRS',);
        $pairsSubject.next(pairs);
      }
    });

    socket.emit("subscribe-signals15m", JSON.stringify({filter}));    

    socket.on("signals15m", (data: any) => {

      console.log('SIGNALS@@@@@', data );
      
      const signals = JSON.parse(data)
      
      if (Object.keys(signals).length > 0) {
        console.log('EMMITING SIGNALS',);
        $signalsSubject.next(signals);
      }
    });

    return () => {
      socket.disconnect(); // Disconnect from the socket.io server when the component unmounts
    };
  }, []);

  const pairs$ = $pairsSubject.pipe(shareReplay(1))
  const signals$ = $signalsSubject.pipe(shareReplay(1))


  return (
    <Signals15mDataContext.Provider value={{pairs$, signals$}}>
      {children}
    </Signals15mDataContext.Provider>
  );
}

export { Signals15mDataContext, Signals15mDataProvider };