import React from "react";

const CONFIG_KEY = 'appConfig';
const CONFIG_CHANGE_EVENT = 'appConfigChanged';

export interface AppConfig {
  alerts: {
    percentDistance: string,
    minVolume: string,
    active: boolean,
  }
  // Add other config properties as needed
}

export const defaultConfig: AppConfig = {
  alerts: {
    percentDistance: '1',
    minVolume: '',
    active: false,
  }
};

export const getConfig = (): AppConfig => {
  const storedConfig = localStorage.getItem(CONFIG_KEY);
  return storedConfig ? JSON.parse(storedConfig) : defaultConfig;
};

export const setConfig = (newConfig: Partial<AppConfig>): void => {
  const currentConfig = getConfig();
  const updatedConfig = { ...currentConfig, ...newConfig };
  localStorage.setItem(CONFIG_KEY, JSON.stringify(updatedConfig));
  window.dispatchEvent(new Event(CONFIG_CHANGE_EVENT));
};

export const useConfigListener = (callback: (config: AppConfig) => void): void => {
  React.useEffect(() => {
    const handleConfigChange = () => {
      const newConfig = getConfig();
      callback(newConfig);
    };

    window.addEventListener(CONFIG_CHANGE_EVENT, handleConfigChange);
    return () => {
      window.removeEventListener(CONFIG_CHANGE_EVENT, handleConfigChange);
    };
  }, [callback]);
};