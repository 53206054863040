import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import moment from 'moment';
import styled from 'styled-components'
import { BrowserRouter } from "react-router-dom";
import { Card, Label } from './global/styles';

import WebsocketConsumer from './WebsocketConsumer';

import { PairDataProvider } from "./PairDataProvider";

export default function StocksListPage() {

  return (
    <div className="App">

        <PairDataProvider>
          <WebsocketConsumer />
        </PairDataProvider>

    </div>
  );
}
