import React from 'react';
import { AppConfig, getConfig, useConfigListener } from './configManager';

export const useConfig = (): AppConfig => {
  const [config, setConfig] = React.useState<AppConfig>(getConfig());

  useConfigListener((newConfig) => {
    setConfig(newConfig);
  });

  return config;
};