import moment from "moment";
import SouthEastRoundedIcon from '@mui/icons-material/SouthEastRounded';
import NorthEastRoundedIcon from '@mui/icons-material/NorthEastRounded';

export function convertMinutesToHours(minutes) {

    // Use moment.js to convert the minutes to hours and minutes
    const duration = moment.duration(minutes, 'minutes');
    const hours = Math.floor(duration.asHours());
    const remainingMinutes = duration.minutes();

    // Conditional check to display only minutes if no hours
    if (hours > 24) {
      const days = Math.floor(hours / 24);
      const remainingHours = hours % 24;
      let ret = ''
      if (days > 0) {
        ret += `${days}d `
      }
      if (remainingHours > 0) {
        ret += ` ${remainingHours}h `
      }
      if (remainingMinutes > 0) {
        ret += ` ${remainingMinutes}m`
      }

      return ret
    } else {
      return `${hours} h ${remainingMinutes} m`
    }
  }

  export function getDirectionIcon(direction: string, delayed: boolean = false, size: number = 18) {
    const pillowSize = size === 10 ? 'pillow-small' : '';
    if (direction === 'up') {
      if (delayed) {
        return <div className={`pillow orange ${pillowSize}`}>-</div>
      } else {
        return <div className={`pillow green ${pillowSize}`}><NorthEastRoundedIcon sx={{ fontSize: size }} /></div>
      }
    } else if (direction === 'down') {
      if (delayed) {
        return <div className={`pillow orange ${pillowSize}`}>-</div>
      } else {
        return <div className={`pillow red ${pillowSize}`}><SouthEastRoundedIcon sx={{ fontSize: size }} /></div>
      }
    } else {
      return <div className={`pillow grey ${pillowSize}`}></div>
    }
  }
  
  export function getDelayedInfo(ts: number, tf: number) {
  
    const tsNow = moment().unix();
    const seconds = tf * 60; // allowed diff in seconds 
  
    const diff = tsNow - ts;
  
    if (diff > seconds * 2 + 180) { // because we are always counting everying for last closed candle, so 1 period behind. 180 seconds is an arbitrary safety margin
      return true
    } else {
      return false
    }
  }

  export function formatNumberK(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2) + 'm';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(2) + 'k';
    }
    return num.toString();
  }